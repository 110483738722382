/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // All screen states
        var is_iPad = navigator.userAgent.match(/iPad/i) != null;

        var is_vertical = true;
        if(window.innerHeight < window.innerWidth) {
          var is_vertical = false;
        }

        if(is_iPad) {
          $('.scrollable').niceScroll({
            scrollspeed: 80,
            cursorfixedheight: 0,
            autohidemode: 'hidden',
            cursorcolor: 'transparent',
            backgroundcolor: 'transparent',
            cursordragontouch: true,
          });

          $('.scrollable').getNiceScroll().hide();
        }

        $(function() {
          FastClick.attach(document.body);
        });

        if(is_iPad) {
          $('#floorplan-options').addClass('visible');
          $('#reload').addClass('active');
          if(is_vertical) {
            $('#navigation').addClass('enabled');
            $('body').addClass('nav-enabled');
          }
        } else {
          $('#default').addClass('visible');
          $('#reload').addClass('active');
        }

        $videoSrc = $('#homeVideo').attr('src');

        $('*[data-make-screen-visible]').on('click', function(e) {
          $('.overlay').removeClass('visible');
          $('.overlay-close').removeClass('visible');
          $('.screen').removeClass('visible');
          $('#' + $(this).attr('data-make-screen-visible')).addClass('visible');

          if($(this).attr('data-make-screen-visible') !== 'default') {
            if(is_vertical) {
              $('#navigation').addClass('enabled');
              $('body').addClass('nav-enabled');
            }

            $('#reload').removeClass('active');
            $('#homeVideo').attr('src', '');
          } else {
            if(is_vertical) {
              $('#navigation').removeClass('enabled');
              $('body').removeClass('nav-enabled');
            }

            $('#reload').addClass('active');

            $('#homeVideo').attr('src', $videoSrc);
          }

          if($(this).attr('data-make-screen-visible') === 'floorplan-options') {
            $('#navigation--wrapper').addClass('show-shade');
          } else {
            $('#navigation--wrapper').removeClass('show-shade');
          }

          // clearing all bedroom mix selectors
          $('.ul-select').removeClass('active');
          $('.toggle').removeClass('active');

          // Navigation state
          var string = $(this).attr('data-make-screen-visible');
          var currentSegments = string.split('-');
          var currentMain = currentSegments[0];
          if(currentMain === 'floorplan' || currentMain === 'plan' || currentMain === 'floorplans') {
            $('#navigation li').removeClass('active');
            $('.nav-home').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
            console.log('this one');

            if ($('.swiper-slide-active').hasClass('hide-furniture')) {
              $('.toggle--furniture').fadeOut();
            } else {
              $('.toggle--furniture').fadeIn();
            }
          }
          if(currentMain === 'siteplan' || currentMain === 'siteplan1' || currentMain === 'siteplan2' || currentMain === 'siteplan3' || currentMain === 'siteplan4') {
            $('#navigation li').removeClass('active');
            $('.nav-plan[data-make-screen-visible=' + currentMain + ']').addClass('active');
            $('.siteplan-datum .siteplan-navigation').addClass('animate');
          }
          if(currentMain === 'gallery') {
            $('#navigation li').removeClass('active');
            $('.nav-gallery').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
          }
          if (currentMain === 'parkside') {
            $('#navigation li').removeClass('active');
            $('.nav-parkside').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
          }
          if(currentMain === 'clubhouse') {
            $('#navigation li').removeClass('active');
            $('.nav-clubhouse').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
          }
          if(currentMain === 'views') {
            $('#navigation li').removeClass('active');
            $('.nav-views').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
          }
          if(currentMain === 'masterplan') {
            $('#navigation li').removeClass('active');
            $('.nav-masterplan').addClass('active');
            $('.siteplan-datum .siteplan-navigation').removeClass('animate');
          }

          //console.log('making screen ' + $(this).attr('data-make-screen-visible') + ' visible');
        });

        $('*[data-make-overlay-visible]').on('click', function(e) {
          var string = $(this).attr('data-make-overlay-visible');
          var currentSegments = string.split('-');
          var currentMain = currentSegments[0];

          $('.overlay').removeClass('visible');
          $('#' + $(this).attr('data-make-overlay-visible')).addClass('visible');

          $('span.overlay-close').addClass('visible');

          $('.ul-select').removeClass('active');
          $('.toggle').removeClass('active');

          //console.log('making overlay ' + $(this).attr('data-make-overlay-visible') + ' visible');
        });

        $('#reload').on('click', function(e) {
          window.location.href = "/";
        });

        $('.overlay-close').on('click', function(e) {
          $(this).removeClass('visible');
          $('.overlay').removeClass('visible');

          //console.log('closing all overlays');
        });

        $(window).on('load', function() {
          $('.annotation-pin').on('click', function(e) {
            if(!$(this).hasClass('active')) {
              $('.annotation-pin').removeClass('active');
              $('.annotation-text').removeClass('active');

              var oldLeft = parseInt($('.annotation-processed').css("left")) + 7;
              var oldTop = parseInt($('.annotation-processed').css("top")) + 7;
              $('.annotation-processed').css({"left" : oldLeft, "top" : oldTop});
              $('.annotation-processed').removeClass('annotation-processed');

              $(this).addClass('active');

              var index = $(this).index() + 1;
              $(this).parent('.pins').next('.captions').find('.annotation-text:nth-child(' + index + ')').addClass('active');

              var newLeft = parseInt($(this).css("left")) - 7;
              var newTop = parseInt($(this).css("top")) - 7;
              $(this).css({"left" : newLeft, "top" : newTop});
              $(this).addClass('annotation-processed');
            }
          });
        });

        $('.toggle--furniture').on('click', function(e) {
          if($('body').hasClass('furniture-active')) {
            $('body').removeClass('furniture-active');

            $('.furniture').removeClass('visible');
            $('.floor').addClass('visible');
          } else {
            $('body').addClass('furniture-active');

            $('.furniture').addClass('visible');
            $('.floor').removeClass('visible');
          }
        });

        $(window).resize(function() {
          $('#wrapper').height(window.innerHeight);
          $('#wrapper').width(window.innerWidth);

          if(window.innerWidth > window.innerHeight) {
            $('body').addClass('horizontal');
          } else {
            $('body').addClass('vertical');
          }

          $('.floorplan-card--wrapper').each(function(index) {
            if($(this) && ($('.floorplan-card--wrapper')[index].scrollWidth > $('.floorplan-card--wrapper')[index].offsetWidth)) {
              $(this).removeClass('hide-right-shade');
            } else {
              $(this).addClass('hide-right-shade');
            }
          });
        }).resize();

        $('.floorplan-card--wrapper').scroll( function() {
          var $width = $(this).outerWidth();
          var $scrollWidth = $(this)[0].scrollWidth;
          var $scrollLeft = $(this).scrollLeft();

          if ($scrollWidth - $width === $scrollLeft) {
            $(this).addClass('hide-right-shade');
          } else {
            $(this).removeClass('hide-right-shade');
          }

          if ($scrollLeft === 0) {
            $(this).removeClass('show-left-shade');
          } else {
            $(this).addClass('show-left-shade');
          }
        });

        $('#floorplan-options ul').scroll(function() {
          var $scrollTop = $(this).scrollTop();

          if ($scrollTop > 1) {
            $(this).parent().addClass('show-top-shade');
          } else {
            $(this).parent().removeClass('show-top-shade');
          }
        });

        $('#default .slides').slick({
          infinite: true,
          autoplaySpeed: 5000,
          autoplay: true,
          fade: true,
          cssEase: 'linear',
          arrows: false,
          pauseOnHover: false
        });

        /***********
         * This is the plans slideshow.
         *
         */
        var $originalDataAttr;

        var swiperBedroomMix = $('#floorplans').swiper({
          noSwiping: true,

          onInit: function() {
            $('#floorplans .floorplan-navigation').first().find('.floorplan-card').first().addClass('active');
          },
          onSlideChangeStart: function() {
            if (swiperBedroomMix.activeIndex < swiperBedroomMix.previousIndex) {
              currentSlide = $('#floorplans .container-inner').eq(swiperBedroomMix.activeIndex).parent();
              currentSlide.find('.floorplan-navigation .floorplan-card').last().addClass('active');

              $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
              $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
              // console.log('A' + $siteplanAttr);

              if ($('#' + $originalDataAttr).hasClass('hide-furniture')) {
                $('.toggle--furniture').fadeOut();
              } else {
                $('.toggle--furniture').fadeIn();
              }

              var result = $originalDataAttr.split('-');
              //


              // we need to make the 1 for siteplan dynamic.
              // we need to make the appropriate 'plan" highlighted in the nav, connected to above


              //
              var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

              $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
              $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floorplan' + $originalDataAttr);

              swiperFloorplans[swiperBedroomMix.activeIndex].slideTo(100000, 0, false);
            } else {
              $('.floorplan-navigation .floorplan-card').removeClass('active');
              currentSlide = $('#floorplans .container-inner').eq(swiperBedroomMix.activeIndex).parent();
              currentSlide.find('.floorplan-navigation .floorplan-card').first().addClass('active');

              $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
              $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
              // console.log('B' + $siteplanAttr);

              if ($('#' + $originalDataAttr).hasClass('hide-furniture')) {
                $('.toggle--furniture').fadeOut();
              } else {
                $('.toggle--furniture').fadeIn();
              }

              var result = $originalDataAttr.split('-');
              var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

              $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
              $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);

              swiperFloorplans[swiperBedroomMix.activeIndex].slideTo(0, 0, false);
            }
          }
        });

        var swiperFloorplans = [];
        $('.container-inner').each(function(index, element) {
          $(this).addClass('swiper-floorplan-' + index);

          var swiperFloorplan = $('.swiper-floorplan-' + index).swiper({
            nested: true,
            onSlideChangeStart: function() {
              $('.floorplan-navigation .floorplan-card').removeClass('active');
              currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
              currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplan.activeIndex).addClass('active');

              $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
              $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
              // console.log('C' + $siteplanAttr);

              var result = $originalDataAttr.split('-');
              var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

              $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
              $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);

              if($('#' + $originalDataAttr).hasClass('hide-furniture')) {
                $('.toggle--furniture').fadeOut();
              } else {
                $('.toggle--furniture').fadeIn();
              }
            }
          });
          swiperFloorplans.push(swiperFloorplan);
        });

        // $('.floorplan-options .floorplan-card').on('click', function(e) {
        //   swiperBedroomMix.slideTo($(this).attr('data-make-outer-slide-visible'), 0, false);
        //   swiperFloorplans[$(this).attr('data-make-outer-slide-visible')].slideTo($(this).attr('data-make-inner-slide-visible'), 0, false);

        //   $('.floorplan-navigation .floorplan-card').removeClass('active');

        //   currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
        //   currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplans[swiperBedroomMix.activeIndex].activeIndex).addClass('active');

        //   $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
        //   $('.plans .toggle--siteplan').attr('data-make-screen-visible', 'site' + $originalDataAttr);
        //   $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);
        // });

        $('.plans .bedroom-mix .ul-select li').on('click', function(e) {
          swiperBedroomMix.slideTo($(this).attr('data-make-outer-slide-visible'), 0, false);
          swiperFloorplans[$(this).attr('data-make-outer-slide-visible')].slideTo(0, 0, false);

          $('.floorplan-navigation .floorplan-card').removeClass('active');

          currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
          currentSlide.find('.floorplan-navigation .floorplan-card').first().addClass('active');

          $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
          $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
          // console.log('D' + $siteplanAttr);

          if ($('#' + $originalDataAttr).hasClass('hide-furniture')) {
            $('.toggle--furniture').fadeOut();
          } else {
            $('.toggle--furniture').fadeIn();
          }

          var result = $originalDataAttr.split('-');
          var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

          $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
          $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);
        });

        $('.plans .floorplan-navigation .floorplan-card--wrapper .floorplan-card').on('click', function(e) {
          swiperFloorplans[$(this).attr('data-make-outer-slide-visible')].slideTo($(this).attr('data-make-inner-slide-visible'), 0, false);

          $('.floorplan-navigation .floorplan-card').removeClass('active');

          currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
          currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplans[swiperBedroomMix.activeIndex].activeIndex).addClass('active');

          $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
          $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
          // console.log('E' + $siteplanAttr);

          if ($('#' + $originalDataAttr).hasClass('hide-furniture')) {
            $('.toggle--furniture').fadeOut();
          } else {
            $('.toggle--furniture').fadeIn();
          }

          var result = $originalDataAttr.split('-');
          var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

          $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
          $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);
        });

        $('.floorplan-options .floorplan-card').on('click', function() {
          // we need to find the index of outer and inner slide
          $('.floorplan-navigation .floorplan-card').removeClass('active');
          currentSlide = $('#plan-' + $(this).attr('data-make-floorplan-visible'));
          innerIndex = currentSlide.index();
          outerIndex = currentSlide.parent().parent().parent().index();

          swiperBedroomMix.slideTo(outerIndex, 0, false);
          swiperFloorplans[outerIndex].slideTo(innerIndex, 0, false);

          currentSlideID = currentSlide.attr('id');
          var result = currentSlideID.split('-');

          // console.log('G');

          currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
          currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplans[swiperBedroomMix.activeIndex].activeIndex).addClass('active');

          $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);

          var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];
          $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
          $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + currentSlideID);
        });

        $('.siteplan-datum .toggle--siteplan').on('click', function() {
          // we need to find the index of outer and inner slide
          $('.floorplan-navigation .floorplan-card').removeClass('active');
          currentSlide = $('#plan-' + $(this).attr('data-make-floorplan-visible'));
          innerIndex = currentSlide.index();
          outerIndex = currentSlide.parent().parent().parent().index();

          // console.log('toggle');

          swiperBedroomMix.slideTo(outerIndex, 0, false);
          swiperFloorplans[outerIndex].slideTo(innerIndex, 0, false);

          currentSlideID = currentSlide.attr('id');
          var result = currentSlideID.split('-');

          currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
          currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplans[swiperBedroomMix.activeIndex].activeIndex).addClass('active');

          $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);

          var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];
          $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
          $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + currentSlideID);
        });

        $('.siteplan--wrapper--inner svg [data-make-screen-visible]').on('click', function() {
          // console.log('here');
          $('.floorplan-navigation .floorplan-card').removeClass('active');
          currentSlide = $('#plan-' + $(this).attr('data-make-floorplan-visible'));
          innerIndex = currentSlide.index();
          outerIndex = currentSlide.parent().parent().parent().index();

          swiperBedroomMix.slideTo(outerIndex, 0, false);
          swiperFloorplans[outerIndex].slideTo(innerIndex, 0, false);

          currentSlide = $('.swiper-floorplan-' + swiperBedroomMix.activeIndex).parent();
          currentSlide.find('.floorplan-navigation .floorplan-card').eq(swiperFloorplans[swiperBedroomMix.activeIndex].activeIndex).addClass('active');

          $originalDataAttr = currentSlide.find('.swiper-slide-active').attr('id');
          $siteplanAttr = parseInt(currentSlide.find('.swiper-slide-active').attr('data-siteplan')) + parseInt(1);
          // console.log('F' + $siteplanAttr);

          if ($('#' + $originalDataAttr).hasClass('hide-furniture')) {
            $('.toggle--furniture').fadeOut();
          } else {
            $('.toggle--furniture').fadeIn();
          }

          var result = $originalDataAttr.split('-');
          var herewego = 'site' + result[0] + $siteplanAttr + '-' + result[1] + '-' + result[2];

          $('.plans .toggle--siteplan').attr('data-make-screen-visible', herewego);
          $('.plans .toggle--zoom').attr('data-make-overlay-visible', 'floor' + $originalDataAttr);
        });

        $('.siteplan-datum .floorplan-card').on('click', function() {
          $('.siteplan-datum .siteplan-navigation').removeClass('animate');
        });

        if(is_vertical === false) {
          $('#default').attr('data-make-screen-visible', 'siteplan1');
          $('.floorplan-tools').hide();
          $('#gallery').hide();
          $('#parkside').hide();
          $('#floorplans').hide();
          $('#floorplan-options').hide();
          $('body').addClass('horizontal');
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        window.videoSrc = $('#homeVideo').attr('src');

        $('.ul-select').on('click', 'li', function(event) {
          $(this).parent().removeClass('active');
        });

        $('.toggle').on('click', function(event) {
          if($(this).hasClass('active')) {
            $(this).removeClass('active');
            $('.ul-select').removeClass('active');
          } else {
            $(this).next('.ul-select').addClass('active');
            $('.ul-select').children().removeClass('active');
            $(this).addClass('active');
          }
        });

        $('.floorplan--item.overlay .floor img').each(function(_, img) {
          var $this = $(this);

          $this.on('load', function() {
            if($this.height() > $this.width()) {
              $this.addClass('vertical');
            } else {
              $this.addClass('horizontal');
            }
          });
        });

        $('.floorplan--item.overlay .furniture img').each(function(_, img) {
          var $this = $(this);

          $this.on('load', function() {
            if($this.height() > $this.width()) {
              $this.addClass('vertical');
            } else {
              $this.addClass('horizontal');
            }
          });
        });

        $('.gallery-overlay.overlay .media-item img').each(function(_, img) {
          var $this = $(this);

          $this.on('load', function() {
            if($this.height() > $this.width()) {
              $this.addClass('portraitJS');
              $this.parent().parent().addClass('portraitJS');
            } else {
              $this.addClass('landscapeJS');
            }
          });
        });

        var is_iPad = navigator.userAgent.match(/iPad/i) != null;
        if(!is_iPad) {
          var screensaver_timer = null;
          $(window).on('touchstart touchmove mousemove mouseenter', function(e) {

            if(screensaver_timer) {
              // console.log('resetting timer');
              clearTimeout(screensaver_timer);
            }

            screensaver_timer = setTimeout(function() {
              // set default screen active
              $('#homeVideo').attr('src', window.videoSrc);

              $('.overlay').removeClass('visible');
              $('.overlay-close').removeClass('visible');
              $('.screen').removeClass('visible');
              $('#navigation').removeClass('enabled');
              $('body').removeClass('nav-enabled');
              $('#reload').addClass('active');
              $('#default').addClass('visible');
            }, 900000);
          });
        }

        $('.pins .annotation-pin').first().addClass('active');
        $('.captions .annotation-text').first().addClass('active');

        // $(document).on('load', function(){
          $('#loading').fadeOut('slow');
        // });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_touchscreen': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.video-toggle').on('click', function() {
          const player = new Vimeo.Player($('#' + $(this).data('make-overlay-visible') + ' iframe'));
          player.play();
        });

        $('.overlay-close').on('click', function() {
          // TODO: need to get the current playing video overlay ID
          const player = new Vimeo.Player($('.embed--wrapper iframe'));
          player.unload();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
